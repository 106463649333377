app.factory('datePickerService', ['$rootScope', function ($rootScope) {

    var datePickerServiceFactory = {};

    var _dateOptions = {
        min: new Date(1900, 0, 1),
        max: new Date(2999, 11, 31),
        value: nowDate(),
        parseFormats: ["ddMMyyyy"], format: "dd/MM/yyyy",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(nowDate());
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };
    var _fromDateOptions = {
        min: new Date(1900, 0, 1),
        max: new Date(2999, 11, 31),
        value: firstDayDate(),
        parseFormats: ["ddMMyyyy"], format: "dd/MM/yyyy",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(firstDayDate());
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };
    var _toDateOptions = {
        min: new Date(1900, 0, 1),
        max: new Date(2999, 11, 31),
        value: lastDayDate(),
        parseFormats: ["ddMMyyyy"], format: "dd/MM/yyyy",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(lastDayDate());
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };

    var _dateOptionsMMMMyyyy = {
        depth: "year",
        start: "year",
        format: "MMMM yyyy",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(nowDate());
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };
    var _fromDateOptionsMMMMyyyy = {
        depth: "year",
        start: "year",
        format: "MMMM yyyy",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(nowDate());
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };
    var _toDateOptionsMMMMyyyy = {
        depth: "year",
        start: "year",
        format: "MMMM yyyy",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(nowDate());
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };

    var _dateOptionsyyyy = {
        depth: "decade",
        start: "decade",
        format: "yyyy",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(nowDate());
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };
    var _fromDateOptionsyyyy = {
        depth: "decade",
        start: "decade",
        format: "yyyy",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(nowDate());
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };
    var _toDateOptionsyyyy = {
        depth: "decade",
        start: "decade",
        format: "yyyy",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(nowDate());
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };

    var _timeOptions = {
        format: "HH:mm",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(new Date(Date.now()));
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };
    var _fromTimeOptions = {
        format: "HH:mm",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(new Date(Date.now()));
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };
    var _toTimeOptions = {
        format: "HH:mm",
        change: function (e) {
            if ((this.value() instanceof Date) == false) {
                this.value(new Date(Date.now()));
            }
            updateApplyDigest(this);
        },
        update: function (value) { }
    };

    function updateApplyDigest(obj) {
        if (obj.options.update) {
            obj.options.update(obj.value());
        }

        var scope = obj.$angular_scope;
        if (scope.$root.$$phase != '$apply' && scope.$root.$$phase != '$digest') {
            scope.$apply();
        }
    }

    function nowDate() {
        var d = new Date(Date.now());
        d.setHours(0, 0, 0, 0);
        return d;
    }

    function firstDayDate() {
        var d = new Date(nowDate().getFullYear(), nowDate().getMonth(), 1);
        return d;
    }

    function lastDayDate() {
        var d = new Date(nowDate().getFullYear(), nowDate().getMonth() + 1, 0);
        return d;
    }

    datePickerServiceFactory.dateOptions = _dateOptions;
    datePickerServiceFactory.fromDateOptions = _fromDateOptions;
    datePickerServiceFactory.toDateOptions = _toDateOptions;
    
    datePickerServiceFactory.dateOptionsMMMMyyyy = _dateOptionsMMMMyyyy;
    datePickerServiceFactory.fromDateOptionsMMMMyyyy = _fromDateOptionsMMMMyyyy;
    datePickerServiceFactory.toDateOptionsMMMMyyyy = _toDateOptionsMMMMyyyy;

    datePickerServiceFactory.dateOptionsyyyy = _dateOptionsyyyy;
    datePickerServiceFactory.fromDateOptionsyyyy = _fromDateOptionsyyyy;
    datePickerServiceFactory.toDateOptionsyyyy = _toDateOptionsyyyy;

    datePickerServiceFactory.timeOptions = _timeOptions;
    datePickerServiceFactory.fromTimeOptions = _fromTimeOptions;
    datePickerServiceFactory.toTimeOptions = _toTimeOptions;

    datePickerServiceFactory.nowDate = nowDate;
    datePickerServiceFactory.firstDayDate = firstDayDate;
    datePickerServiceFactory.lastDayDate = lastDayDate;

    return datePickerServiceFactory;

}]);